var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.col.hideActions)?_c('div',{staticClass:"d-flex align-items-center",class:{ 'position-absolute': _vm.floating },style:({
    columnGap: '8px',
    transform: _vm.floating ? 'translateY(-50%)' : undefined,
    top: _vm.floating ? '50%' : undefined,
    right: _vm.floating ? 0 : undefined,
    maxWidth: _vm.floating ? '100%' : undefined,
    flexWrap: _vm.floating ? 'wrap' : undefined,
  })},[_vm._l((_vm.listAllActions ? _vm.buttons.concat(_vm.moreActions) : _vm.buttons),function(btn,btnIdx){return _c('fragment',{key:("data-table-" + (typeof btn.icon === 'string' ? btn.icon : '') + btnIdx + _vm.i + _vm.j)},[_c('b-button',{staticClass:"show-btn",attrs:{"id":("data-table-" + (typeof btn.icon === 'string' ? btn.icon : '') + btnIdx + _vm.i + _vm.j),"title":_vm.$t(btn.label)},on:{"click":function($event){$event.stopPropagation();return (function (e) { return btn.onClick(_vm.row, _vm.tableStore, e); })($event)}}},[_c('feather-icon',{attrs:{"icon":_vm.isFunction(btn.icon)
          ? btn.icon(_vm.row, _vm.tableStore)
          : btn.icon,"size":"17"}})],1),(btn.label)?_c('b-tooltip',{attrs:{"target":("data-table-" + (typeof btn.icon === 'string' ? btn.icon : '') + btnIdx + _vm.i + _vm.j),"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.isFunction(btn.label) ? btn.label(_vm.row) : btn.label))+" ")]):_vm._e()],1)}),(!_vm.listAllActions && _vm.moreActions.length > 0)?_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"b-toggle.[`more-${buttons.concat(moreActions).length}${i}${j}`]",rawName:"v-b-toggle.[`more-${buttons.concat(moreActions).length}${i}${j}`]"}],staticClass:"rounded-circle show-btn flex-shrink-0",attrs:{"id":("more-" + (_vm.buttons.concat(_vm.moreActions).length) + _vm.i + _vm.j),"no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"flex-shrink-0",attrs:{"icon":"MoreHorizontalIcon","size":"14","color":"black"}})]},proxy:true}],null,false,3681967786)},_vm._l((_vm.moreActions),function(btn){return _c('b-dropdown-item',{key:btn.key || btn.id,attrs:{"title":_vm.$t(_vm.isFunction(btn.label) ? btn.label(_vm.row) : btn.label)},on:{"click":function($event){$event.stopPropagation();return (function (e) { return btn.onClick(_vm.row, _vm.tableStore, e); })($event)}}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(typeof btn.icon === 'string')?_c('feather-icon',{attrs:{"size":"16","icon":_vm.isFunction(btn.icon)
            ? btn.icon(_vm.row, _vm.tableStore)
            : btn.icon}}):_c(_vm.isFunction(btn.icon)
            ? btn.icon(_vm.row, _vm.tableStore)
            : btn.icon,{tag:"component"}),(_vm.isFunction(btn.label) ? btn.label(_vm.row) : btn.label)?_c('span',{staticClass:"text-truncate block ml-1",staticStyle:{"max-width":"180px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.isFunction(btn.label) ? btn.label(_vm.row) : btn.label))+" ")]):_vm._e()],1)])}),1):_vm._e(),_c('b-tooltip',{attrs:{"target":("more-" + (_vm.buttons.concat(_vm.moreActions).length) + _vm.i + _vm.j),"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t('data~table~more~actions'))+" ")])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }